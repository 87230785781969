import './App.css';



function App() {
  const GoWhatsapp = () => {
    window.open('https://wa.me/+917666222223?text=Hello%20Sir,%20I%20Want%20Id%20with%20best%20Offers', '_blank');
  }


  const GoWhatsapp12 = () => {
    window.open('https://wa.me/+917666222224?text=Hello%20Sir,%20I%20Want%20Id%20With%2015%%20Bonus', '_blank');
  }
  return (
    <div className="App">
      <div className='Post' onClick={GoWhatsapp12} ><img src="2.jpg" alt="" /></div>
      <div className='Slogan' > 24x7 Premium Service </div>


      <div className='Post' onClick={GoWhatsapp12} ><img src="SV.jpg" alt="" /></div>


      <div className='Offer' onClick={GoWhatsapp} >Get 15% Extra</div>
      <div className='WhyUs' >
        <div>100% Secure</div>
        <div>100% Genuine</div>
        <div>100% Guaranteed</div>
      </div>
      <div className='HeroBtn Blink' onClick={GoWhatsapp} >Get Your ID !</div>

      <div className='Slogan' > Best Offers </div>
      <div className='Slogan' > 24x7 Premium Service </div>

      <div className='HeroBtn Blink' style={{ background: 'Gold' }} onClick={GoWhatsapp} >7 666 22222 3</div>
      <div className='Slogan' > 150+ Branches </div>
      <div className='Slogan' > 7 666 22222 4</div>
      <div className='WhyUs' >
        <div className='Slogan' > 200+ Games Available </div>
      </div>
      <div className='Post' onClick={GoWhatsapp12} ><img src="3.jpg" alt="" /></div>

      <div className='WhyUs' >
        <div className='Slogan' > Withdrawal in 7 Minutes Only</div>
      </div>

      <div className='Post' onClick={GoWhatsapp12} ><img src="1.jpg" alt="" /></div>
    </div>
  );
}

export default App;
